import axios from '../helpers/api';
import db from '@/services/pouchDb';
import _ from 'lodash';
import helper from '../helpers/custom/index';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import moment from 'moment';
import { async } from '../libs/exceljs/exceljs.bare';

const { version } = require('./../../package.json');
const user = JSON.parse(localStorage.getItem('user'));

export const geoAssetService = {
    getMapAssetLastDeviceTime,
    getAssetPositionsList,
    getAllAssets,
    getAssetDeviceUsageReport,
    getAssetNotificationReport,
    getAssetById,
    getAssetGroups,
    getAssetGroup,
    getDeviceTypes,
    getAssetDeviceList,
    updateAsset,
    insertAsset,
    getAssetDetailsById,
    getAssetTrips,
    getAssetTripsSample,
    getAssetTripReports,
    getHotSpotReport,
    getStoppageHotSpotReport,
    getAssetStopReports,
    getAssetEngineReports,
    getPositions,
    updateAssetGroup,
    insertAssetGroup,
    deleteAssetGroup,
    getAssetCategories,
    getAssetCategory,
    updateAssetCategory,
    insertAssetCategory,
    deleteAssetCategory,
    getAssetAlerts,
    getAssetEvents,
    updateAssetOdometer,
    updateAssetEngineHours,
    updateAssetMapIcon,
    getAssetCrashes,
    getAssetShareLinks,
    insertAssetShareLink,
    updateAssetShareLink,
    deleteShareLinks,
    getAllAssetInfo,
    geAssetTenants,
    updateAssetTenant,
    getAssetsStatusReport,
    getAttachedAssetsReport,
    getAssetsOfAllDecendents,
    getDeviceBatteryReport,
    getDeviceOfflineBatteryReport,
    getBulkRegistrations,
    updateAssetTenants

};
async function getMapAssetLastDeviceTime(page, size, sortField = 'lastUpdate', sortOrder, minutes) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'lastUpdate';
    sortOrder = sortOrder || 'DESC';
    minutes = minutes || 1;
    if (navigator.onLine) {
        const res = await axios.get(
            `/api/geo/asset/map/last-update/${minutes}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
        );
        return res.data;
    } else {
        //ToDo
    }
}
async function updateAsset(id, asset_infoRow, asset_devicesArray) {
    const data = Object.assign(asset_infoRow, { asset_devices: asset_devicesArray });
    if (navigator.onLine) {
        const res = await axios.put(`api/geo/asset/${id}`, data);
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const doc = await db.userDB.get(id);
            await db.userDB.put(
                {
                    _id: id,
                    _rev: doc._rev,
                    asset_info: data,
                },
                { force: true },
            );

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    version: version,
                    entityId: id,
                    userId: helper.getUserId(),
                    entityType: 'asset_info',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'UPDATE',
                },
            });

            return {
                data: {},
                result: {
                    success: true,
                    message: 'Asset updated successfully',
                },
            };
        } catch (error) {
            //console.log("update Activity error ", error);
        }
    }
}
async function insertAsset(asset_infoRow, asset_devicesArray) {
    const data = Object.assign(asset_infoRow, { asset_devices: asset_devicesArray });
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        const res = await axios.post('api/geo/asset/', data);
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            await db.userDB.put({
                _id: data.Id,
                asset_info: data,
            });
            let activity_global = uuidv4();
            await db.globalsDB.put({
                _id: activity_global,
                change: {
                    version: version,
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'asset_info',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });

            let masterId = uuidv4();
            let master_status = {
                Id: masterId,
                itemId: data.Id,
                itemType: 'asset_info',
                dateCreated: DateTime.now(),
                status: data.status || 'PENDING',
            };

            await db.userDB.put({
                _id: masterId,
                status: master_status,
            });
            return { data: { activity: { Id: data.Id } } };
        } catch (error) {
            console.log(error);
        }
    }
}
/**
 * Get all assets
 * @param {Number} page
 * @param {Number} size
 * @param {String} sortField='asset_info_status.registrationNumber'
 * @param {String} sortOrder
 * @param {String} search
 * @param {DateTime} startDate=null
 * @param {DateTime} endDate=null
 * @param {String} assetStatus='ALL'
 * @param {uuid} assetGroup=null
 * @param {uuid} assetCategory=null
 * @param {boolean} tenantTree=false - used to all asset under the current tenant and descendance
 * @returns {any}
 */
async function getAllAssets(
    page,
    size,
    sortField = 'asset_info_status.registrationNumber',
    sortOrder,
    search,
    startDate = null,
    endDate = null,
    assetStatus = 'ALL',
    assetGroup = null,
    assetCategory = null,
    tenantTree = false,
    LastSelectedID = null,
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();
    assetStatus = assetStatus || '';
    assetGroup = assetGroup || null;
    assetCategory = assetCategory || null;
    LastSelectedID = LastSelectedID || '';

    if (navigator.onLine) {
        const res = await axios.get(
            `api/geo/asset/?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&startDate=${startDate}&endDate=${endDate}&assetStatus=${assetStatus}&assetGroup=${assetGroup}&assetCategory=${assetCategory}&tenantTree=${tenantTree}&LastSelectedID=${LastSelectedID}`,
        );
        return res.data;
    } else {
        //ToDo
    }
}
/**
 *
 * @param {String} search The string to search the assets by
 * @param {object} available The available search options
 */
async function getAssetsOfAllDecendents(page, size, sortField, sortOrder, search, available) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';

    if (navigator.onLine) {
        const res = await axios.get(
            `api/geo/asset/allAvailableTenants/?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&type=${available}`,
        );
        return res.data;
    }
    // else Panic
}
async function getDeviceBatteryReport(
    startDate,
    endDate,
    page,
    size,
    sortField = 'deviceTime',
    sortOrder = 'ASC',
    search = '%%',
    deviceType,
    deviceHardwareType,
    tenants,
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'deviceTime';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/asset/reports/battery/${startDate}/${endDate}/?&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&deviceType=${deviceType}&deviceHardwareType=${deviceHardwareType}&tenants=${tenants}`,
            );
            // console.log(res);
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getDeviceOfflineBatteryReport(
    startDate,
    endDate,
    page,
    size,
    sortField = 'asset_devices_battery_details."battery"',
    sortOrder = 'ASC',
    search = '%%',
    deviceStatus,
    maxBattery,
    deviceType,
    deviceHardwareType,
    tenants,
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'asset_devices_battery_details."battery"';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/asset/reports/battery-offline/${startDate}/${endDate}/?&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&maxBattery=${maxBattery}&deviceStatus=${deviceStatus}&deviceType=${deviceType}&deviceHardwareType=${deviceHardwareType}&tenants=${tenants}`,
            );
            console.log(res);
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getAssetsStatusReport(
    page,
    size,
    sortField = 'asset_info_status.registrationNumber',
    sortOrder,
    search,
    startDate = null,
    endDate = null,
    assetStatus = 'ALL',
    assetGroup = null,
    assetCategory = null,
    deviceType = 'ALL',
    deviceHardwareType = 'ALL',
    tenants,
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();
    assetStatus = assetStatus || '';
    assetGroup = assetGroup || null;
    assetCategory = assetCategory || null;
    deviceType = deviceType || 'ALL';
    deviceHardwareType = deviceHardwareType || 'ALL';

    if (navigator.onLine) {
        const res = await axios.get(
            `api/geo/asset/reports/asset-status?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&startDate=${startDate}&endDate=${endDate}&assetStatus=${assetStatus}&assetGroup=${assetGroup}&assetCategory=${assetCategory}&deviceType=${deviceType}&deviceHardwareType=${deviceHardwareType}&tenants=${tenants}`,
        );
        return res.data;
    } else {
        //ToDo
    }
}
async function getAttachedAssetsReport(
    page,
    size,
    sortField = 'asset_info_status.registrationNumber',
    sortOrder,
    startDate = null,
    endDate = null,
    assetId = null,
    search = null,
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'deviceTime';
    sortOrder = sortOrder || 'DESC';
    search = (search || '').trim();
    assetId = assetId || '';

    if (navigator.onLine) {
        const res = await axios.get(
            `api/geo/asset/reports/attached-asset?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&assetId=${assetId}&startDate=${startDate}&endDate=${endDate}`,
        );
        return res.data;
    } else {
        //ToDo
    }
}
async function getAllAssetInfo(startDate, endDate) {
    if (navigator.onLine) {
        const res = await axios.post(`api/geo/asset/info`, { startDate, endDate });
        return res.data;
    } else {
        // TODO
    }
}

//Should use existing function
// async function getAssets() {
//     if (navigator.onLine) {
//         const res = await axios.get(
//             `api/geo/asset/report/assets`
//         );
//         return res.data;
//     } else {
//         //ToDo

//     }
// }

async function getAssetShareLinks(token) {
    const res = await axios(`/api/geo/assets/share-links/${token}`);
    return res.data;
}


async function updateAssetShareLink(assetShareId, expireDate, status,deviceType) {
    const data = { expireDate: expireDate, assetShareId: assetShareId, status: status,deviceType};
    const res = await axios.put(`api/geo/asset/share-links`, data);
    return res.data;
}

async function deleteShareLinks(assetShareIds) {
    const res = await axios.put(`api/geo/asset/share-links/delete`, { assetShareIds });
    return res.data;
}

async function insertAssetShareLink(assetInfoId, expireDate,deviceType, status = 'ACTIVE') {
    const data = { expireDate: expireDate, assetInfoId: assetInfoId, deviceType:deviceType, status: status };
    const res = await axios.post('api/geo/asset/share-links', data);
    return res.data;
}

async function getAssetById(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/assets/${id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs;
    }
}

async function getAssetDetailsById(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/assets/details/${id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs;
    }
}
async function getAssetDeviceList(assetInfoId) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/assets/devices/${assetInfoId}`);
        return res.data;
    } else {
        //ToDo
    }
}
/** Asset Positions */

async function getAssetPositionsList(
    assetId,
    start,
    end,
    page,
    size,
    sortField,
    sortOrder,
    search,
    protocolSubTypes = 'ALL',
    deviceType = 'ALL',
    deviceHardwareType = 'ALL',
) {
    deviceType = deviceType || 'ALL';
    deviceHardwareType = deviceHardwareType || 'ALL';
    if (navigator.onLine) {
        const res = await axios(
            `/api/geo/position/assets/${assetId}/${start}/${end}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&protocolSubTypes=${protocolSubTypes}&deviceType=${deviceType}&deviceHardwareType=${deviceHardwareType}`,
        );
        return res.data;
    } else {
        //ToDo
    }
}
/////////////////////////////////////////////////////////////////////////////
/// Asset Groups
/////////////////////////////////////////////////////////////////////////////
async function getAssetGroups() {
    if (navigator.onLine) {
        const res = await axios.get(`api/geo/asset-group`);
        return res.data;
    } else {
        //ToDo
    }
}
async function getAssetGroup(Id) {
    if (navigator.onLine) {
        const res = await axios.get(`api/geo/asset-group/${Id}`);
        return res.data;
    } else {
        //ToDo
    }
}

/**
 * Description
 * @param {UUID} id
 * @param {String} Name
 * @param {Array} roleId - List of roles
 * @returns {data}
 */
async function updateAssetGroup(id, Name, roleId) {
    if (navigator.onLine) {
        const body = { name: Name, roleId: roleId };
        const res = await axios.put(`api/geo/asset-group/${id}`, body);
        return res.data;
    } else {
        //ToDo
    }
}
/**
 * Description
 * @param {UUID} id
 * @param {String} Name
 * @param {Array} roleId - List of roles
 * @returns {any}
 */
async function insertAssetGroup(Name, roleId) {
    if (navigator.onLine) {
        const body = { name: Name, roleId: roleId };
        const res = await axios.post(`api/geo/asset-group/`, body);
        return res.data;
    } else {
        //ToDo
    }
}
/**
 * Description
 * @param {UUID} id
 * @returns {any}
 */
async function deleteAssetGroup(id) {
    if (navigator.onLine) {
        const res = await axios.delete(`api/geo/asset-group/${id}`);
        return res.data;
    } else {
        //ToDo
    }
}
/////////////////////////////////////////////////////////////////////////////
/// Asset Category
/////////////////////////////////////////////////////////////////////////////
async function getAssetCategories() {
    if (navigator.onLine) {
        const res = await axios.get(`api/geo/asset-category`);
        return res.data;
    } else {
        //ToDo
    }
}
async function getAssetCategory(id) {
    if (navigator.onLine) {
        const res = await axios.get(`api/geo/asset-category/${id}`);
        return res.data;
    } else {
        //ToDo
    }
}
/**
 * Description
 * @param {UUID} id
 * @param {String} Name
 * @returns {data}
 */
async function updateAssetCategory(id, Name) {
    if (navigator.onLine) {
        const body = { name: Name };
        const res = await axios.put(`api/geo/asset-category/${id}`, body);
        return res.data;
    } else {
        //ToDo
    }
}
/**
 * Description
 * @param {UUID} id
 * @param {String} Name
 * @returns {any}
 */
async function insertAssetCategory(id, Name) {
    id = helper.checkId(id) ? id : uuidv4();
    if (navigator.onLine) {
        const body = { id, name: Name };
        const res = await axios.post(`api/geo/asset-category/`, body);
        //debugger;
        return res.data;
    } else {
        //ToDo
    }
}
/**
 * Description
 * @param {UUID} id
 * @returns {any}
 */
async function deleteAssetCategory(id) {
    if (navigator.onLine) {
        const res = await axios.delete(`api/geo/asset-category/${id}`);
        return res.data;
    } else {
        //ToDo
    }
}
async function getDeviceTypes() {
    if (navigator.onLine) {
        const res = await axios.get(`api/geo/asset/device-type`);
        return res.data;
    } else {
        //ToDo
    }
}

async function getAssetTrips(assetTripsInfo) {
    if (navigator.onLine) {
        try {
            const res = await axios.post(`/api/geo/asset/trips`, assetTripsInfo);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}
async function getAssetTripsSample(assetTripsInfo) {
    if (navigator.onLine) {
        try {
            const res = await axios.post(`/api/geo/asset/trips/sample`, assetTripsInfo);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetDeviceUsageReport(startDate, endDate, page, size, sortField, sortOrder, deviceID) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';

    if (navigator.onLine) {
        let res = await axios.get(
            `/api/geo/asset/reports/device-usage?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&deviceId=${deviceID}&startDate=${startDate}&endDate=${endDate}`,
        );
        return res.data;
    }

    return false;
}

async function getAssetNotificationReport(
    type,
    search = null,
    startDate,
    endDate,
    page,
    size,
    sortField = 'startDate',
    sortOrder = 'DESC',
    deviceType = 'ALL',
    deviceHardwareType = 'ALL',
    tenants,
    
) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'CreateDate';
    sortOrder = sortOrder || 'DESC';
    startDate = moment(startDate).format('yyyy-MM-DDT00:00:00') || DateTime.now().plus({ days: -1 }).toISO();
    endDate = moment(endDate).format('yyyy-MM-DDT23:59:59') || DateTime.now().plus({ days: 7 }).toISO();
    deviceType = deviceType || 'ALL';
    deviceHardwareType = deviceHardwareType || 'ALL';

    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/notification-event/reports/notification?types=${type}&search=${search}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&deviceType=${deviceType}&deviceHardwareType=${deviceHardwareType}&tenants=${tenants}`,
            );
            return res;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getHotSpotReport(
    assetId,
    startDate,
    endDate,
    page,
    size,
    sortField = 'startDate',
    sortOrder = 'DESC',
    fromTime = '',
    toTime = '',
    DayOfTheWeek = '-1',
) {
    assetId = assetId || null;
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    fromTime = fromTime || '';
    toTime = toTime || '';
    DayOfTheWeek = encodeURIComponent(JSON.stringify(DayOfTheWeek || []));
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/asset/reports/hotspots?assetId=${assetId}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&fromTime=${fromTime}&toTime=${toTime}&DayOfTheWeek=${DayOfTheWeek}`,
            );
            return res;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getStoppageHotSpotReport(
    assetId,
    startDate,
    endDate,
    page,
    size,
    sortField = 'startDate',
    sortOrder = 'DESC',
    fromTime = '',
    toTime = '',
    DayOfTheWeek = '-1',
) {
    assetId = assetId || null;
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    fromTime = fromTime || '';
    toTime = toTime || '';
    DayOfTheWeek = encodeURIComponent(JSON.stringify(DayOfTheWeek || []));
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/asset/reports/stoppage-hotspots?assetId=${assetId}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&fromTime=${fromTime}&toTime=${toTime}&DayOfTheWeek=${DayOfTheWeek}`,
            );
            return res;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetTripReports(
    assetId,
    startDate,
    endDate,
    showZeros,
    page,
    size,
    sortField = 'device_trips.startDate',
    sortOrder = 'DESC',
) {
    assetId = assetId || null;
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'device_trips.startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    showZeros = showZeros == true ? 0 : 1;

    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/asset/reports/trips?assetId=${assetId}&startDate=${startDate}&endDate=${endDate}&zeros=${showZeros}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
            );
            return res;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetStopReports(assetId, startDate, endDate, showZeros, page, size, sortField = 'startDate', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    showZeros = showZeros == true ? 0 : 1;

    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/asset/reports/stops?assetId=${assetId}&startDate=${startDate}&endDate=${endDate}&zeros=${showZeros}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
            );
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getAssetEngineReports(assetId, startDate, endDate, showZeros, page, size, sortField = 'startDate', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    showZeros = showZeros == true ? 0 : 1;

    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/geo/asset/reports/engine?assetId=${assetId}&startDate=${startDate}&endDate=${endDate}&zeros=${showZeros}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
            );
            return res;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getPositions(deviceId, startDate, endDate) {
    let info = { deviceId: deviceId, startDate: startDate, endDate: endDate };
    if (navigator.onLine) {
        try {
            const res = await axios.post(`/api/geo/asset/device/positions`, info);
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetAlerts(assetId, startDate, endDate, page = 1, size = 100, sortField = 'createdAt', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 100;
    sortField = sortField || 'createdAt';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().toISO();
    endDate = endDate || DateTime.now().toISO();
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    if (navigator.onLine) {
        try {
            return axios.get(
                `/api/geo/notification-event/asset/${assetId}/alarm?page=${page}&size=${size}&startDate=${encodedStartDate}&endDate=${encodedEndDate}`,
            );
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetEvents(assetId, startDate, endDate, page = 1, size = 100, sortField = '"eventTime"', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 100;
    sortField = sortField || '"eventTime"';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().toISO();
    endDate = endDate || DateTime.now().toISO();
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    if (navigator.onLine) {
        try {
            return axios.get(
                `/api/geo/notification-event/asset/${assetId}/event?page=${page}&size=${size}&startDate=${encodedStartDate}&endDate=${encodedEndDate}&sortField=${sortField}&sortOrder=${sortOrder}`,
            );
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getAssetCrashes(assetId, status, startDate, endDate, page = 1, size = 100, sortField = 'createdAt', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 100;
    sortField = sortField || 'createdAt';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().toISO();
    endDate = endDate || DateTime.now().toISO();
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    if (navigator.onLine) {
        try {
            return axios.get(
                `/api/geo/crash-event/${assetId}/${status}?page=${page}&size=${size}&startDate=${encodedStartDate}&endDate=${encodedEndDate}`,
            );
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

// async function geAssetTenants(assetId) {
//     try {
//         const res = await axios(`/api/tenants/asset_tenant/${assetId}`);
//         return res.data;
//     } catch (error) {
//         return error;
//     }
// }

async function geAssetTenants(assetId) {
    try {
        const res = await axios(`/api/geo/assets/asset_tenant/${assetId}`);
        return res.data;
    } catch (error) {
        return { error: 'Failed to fetch asset tenants' };
    }
}

async function updateAssetOdometer(id, data) {
    const res = await axios.put(`api/geo/assets/${id}/odometer`, data);
    return res.data;
}

async function updateAssetEngineHours(id, data) {
    const res = await axios.put(`api/geo/assets/${id}/engine-hours`, data);
    return res.data;
}
async function updateAssetMapIcon(id, data) {
    const res = await axios.put(`api/geo/assets/${id}/map-icon`, data);
    return res.data;
}
async function updateAssetTenant(assetId, tenantIds) {
    const res = await axios.post(`api/geo/asset/${assetId}/asset_tenant`, { assetId, tenantIds });
    return res.data;
}
async function updateAssetTenants(assetId, tenantIds) {
    const res = await axios.post(`/api/geo/asset/${assetId}/asset_tenants`, { assetId, tenantIds });
    return res.data;
}


async function getBulkRegistrations(registrations) {
    const res = await axios.post(`api/geo/asset/registration/bulk`, { registrationNumbers: registrations });
    return res.data;
}